<template>
  <div>
    <div style="margin-bottom:20px;text-align:left;">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="margin-right: 10px; width: 300px"
        size="small"
      >
      </el-date-picker>
      <el-button type="primary" @click="query()" size="small">查询</el-button>
    </div>
    <el-table :data="tableData" border height="68vh" style="width: 100%;overflow-y:auto;">
      <el-table-column prop="id" label="ID">
        <template slot-scope="scope">
          <div>
            {{ scope.row.id.substring(0, 3) + "****" + scope.row.id.substring(7) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="desc" label="昵称"></el-table-column>
      <el-table-column prop="service" label="所属服务人"></el-table-column>
      <el-table-column prop="createTime" label="日期"></el-table-column>
      <el-table-column prop="" label="">
        <template slot-scope="scope">
          <div class="opt doc">
            <el-button
              size="mini"
              type="primary"
              @click="looktalkhistory(scope.row)"
            >
              查看聊天记录
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage1"
      :page-sizes="pageSizes"
      :page-size="PageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalCount"
      style="text-align:center;margin-top:20px"
    >
    </el-pagination>
    <talklookhistory
      :serve="serve"
      :lookhistory.sync="lookhistory"
      :historyrow="historyrow"
    ></talklookhistory>
  </div>
</template>
<script>
import talklookhistory from "../components/talklookhistory.vue";
export default {
  props: ["serve"],
  components: {
    talklookhistory,
  },
  data() {
    return {
      date: "",
      tableData: [],
      currentPage1: 1,
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10,
      sid: sessionStorage.getItem("servicer_id"),
      lookhistory: false,
      historyrow: "",
    };
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.getlist(val);
    },
    query() {
      if (this.date.length <= 0) {
        this.$message({
          message: "请选择日期",
          type: "error",
        });
        return;
      }
      console.log(this.serve);
      this.getlist(1);
    },
    getlist(page) {
      this.axios
        .get(
          this.serve +
            "/api/UserCount/get_user_register_count?index=" +
            page +
            "&pageSize=" +
            this.PageSize +
            "&start=" +
            this.date[0] +
            "&end=" +
            this.date[1] +
            "&service=" +
            this.sid
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.tableData = res.data.result.list;
            this.totalCount = res.data.result.count;
          } else {
            this.tableData = [];
            this.totalCount = 0;
          }
        });
    },
    //查看聊天记录
    looktalkhistory(row) {
      this.lookhistory = true;
      this.historyrow = row;
    },
  },
};
</script>
