<template>
  <div>
    <el-dialog :visible.sync="show" width="80%" @close="exit()">
      <div class="talkmain" style="height:80vh;">
        <div class="talkname">{{ talkname }}</div>
        <p class="lookhistory" v-if="lookmoreshow" @click="loadmore">
          查看历史记录
        </p>
        <div>
          <div class="talk-main" style="height:65vh;" id="scrolldIV3">
            <div class="talkline" v-for="(item, index) in talklist" :key="index">
              <div class="timeline">{{ item.sendTime }}</div>
              <div class="leftimg" v-if="item.from == toID">
                <img :src="userheadimg" alt="" />
              </div>
              <div class="talkleft" v-if="item.from == toID">
                <p style="margin: 0" v-if="item.type == '0'">
                  <span>
                    {{ item.content }}
                  </span>
                </p>
                <p style="margin: 0" v-if="item.type == '1'">
                  <span>
                    <img :src="item.content" alt="" @click="big(item.content)" />
                  </span>
                </p>
              </div>
              <div class="rightimg" v-if="item.from != toID">
                <img :src="headimg" alt="" />
              </div>
              <div class="talkright" v-if="item.from != toID">
                <span>
                  <i v-if="item.type == '0'"
                    ><b>{{ item.content }}</b></i
                  >
                  <i v-if="item.type == '1'">
                    <img :src="item.content" alt="" @click="big(item.content)" />
                  </i>
                  <i v-if="item.type == 3">
                    <div style="width: 220px">
                      <p>寻医报名表</p>
                      <p>
                        您好，为了便于更好的帮您寻找擅长治疗您疾病的好医生，请点击对话框，详细填写报名表。
                      </p>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '2'">
                    <div style="width: 100px">
                      <p>问诊单:{{ JSON.parse(item.content).name }}</p>
                      <p>诊单编号:{{ JSON.parse(item.content).id }}</p>
                      <p
                        @click="
                          EditZD = true;
                          EditMsg.kid = JSON.parse(item.content).id;
                        "
                      >
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == 4">
                    <div style="width: 300px">
                      <p>精准匹配</p>
                      <div>
                        <div
                          class="talkdocline"
                          v-for="(it, index) in JSON.parse(item.content)"
                          :key="index"
                        >
                          <img :src="it.headimg" alt="" />
                          <div class="wenzi">
                            <p class="wenzidocname">
                              {{ it.name }}<span>{{ it.rank }}</span>
                            </p>
                            <p class="wenzihospatil">{{ it.hospital }}</p>
                            <p class="wenzigoodat">
                              擅长：
                              <span>{{ it.goodat }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '5'">
                    <div style="width: 220px">
                      <p>寻医问诊表</p>
                      <p>
                        您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                      </p>
                      <p
                        @click="
                          EditXY = true;
                          EditXYMsg.kid = JSON.parse(item.content).id;
                          EditXYMsg.noedit = true;
                        "
                      >
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '6'">
                    <div style="width: 220px">
                      <p>{{ JSON.parse(item.content).doc_name }}&nbsp;医生&nbsp;问诊单</p>
                      <p>
                        您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                      </p>
                      <p
                        @click="
                          EditZD = true;
                          EditMsg.kid = JSON.parse(item.content).id;
                          EditMsg.noedit = true;
                        "
                      >
                        查看详情>
                      </p>
                    </div>
                  </i>

                  <i v-if="item.type == '7'">
                    <div style="width: 220px">
                      <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                      <p>ID:{{ JSON.parse(item.content).id }}</p>
                      <p @click="lookchanneldetail(JSON.parse(item.content))">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '8'">
                    <div style="width: 220px">
                      <p style="background: #ff8040">支付问诊费用</p>
                      <p>
                        您好，在线医助给您发来了支付问诊费用请求，点击开始支付。
                      </p>
                      <p @click="lookzfdetail(JSON.parse(item.content).id, 'zf')">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '9'">
                    <div style="width: 220px">
                      <p style="background: #ff8040">支付订单费用</p>
                      <p>订单ID:{{ JSON.parse(item.content).yforderno }}</p>
                      <p>ID:{{ JSON.parse(item.content).id }}</p>
                      <p @click="lookzfdetail(JSON.parse(item.content).id, 'dd')">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '10'">
                    <div style="width: 300px">
                      <p>好转病例</p>
                      <div class="talkdocline" style="border-bottom: none">
                        <img :src="JSON.parse(item.content).headimg" alt="" />
                        <div class="wenzi">
                          <p class="wenzidocname">
                            {{ JSON.parse(item.content).name
                            }}<span>{{ JSON.parse(item.content).ill_name }}</span>
                          </p>
                          <p class="wenzigoodat">
                            <span>{{ JSON.parse(item.content).des }}</span>
                          </p>
                        </div>
                      </div>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '11'">
                    <div style="width: 220px">
                      <p></p>
                      <p>
                        {{ JSON.parse(item.content).name }}给患者:{{
                          changetel(JSON.parse(item.content).id)
                        }}发送了留言短信。
                      </p>
                      <p></p>
                    </div>
                  </i>
                  <i v-if="item.type == '13'">
                    <div style="width: 220px">
                      <p>反馈表</p>
                      <p>
                        您好，为了便于您以后的治疗请认真填写反馈信息表。
                      </p>
                      <p
                        @click="
                          lookfeedback = true;
                          feedbacknoedit = false;
                          feedbackid = JSON.parse(item.content).id;
                        "
                      >
                        查看详情>
                      </p>
                    </div>
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑寻医弹窗开始 -->
    <el-dialog title="编辑寻医单" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="false"
      ></xypreview>
    </el-dialog>
    <!-- 编辑寻医弹窗结束 -->
    <!-- 编辑诊单开始 -->
    <el-dialog title="编辑诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview
        :kid="EditMsg.kid"
        :custid="EditMsg.custid"
        v-if="EditZD"
        :EditZD="EditZD"
        :noedit="false"
      ></preview>
    </el-dialog>
    <!-- 编辑诊单结束 -->
    <!-- 查看反馈表详情开始 -->
    <feedback
      :lookfeedback.sync="lookfeedback"
      :feedbackid="feedbackid"
      :feedbacknoedit="feedbacknoedit"
    ></feedback>
    <!-- 查看反馈表详情结束 -->
    <!-- 查看大图开始 -->
    <el-dialog title="查看图片" :visible.sync="bigpic">
      <img :src="bigpicurl" alt="" width="90%" />
    </el-dialog>
    <!-- 查看大图结束 -->
  </div>
</template>
<script>
import "../assets/talkStyle.css";
import preview from "../components/preview.vue";
import xypreview from "../components/xypreview.vue";
import feedback from "../components/feedback.vue";
export default {
  props: ["serve", "lookhistory", "historyrow"],
  data() {
    return {
      show: false,
      talklist: [],
      indexedDBindex: 1,
      talkname: "",
      lookmoreshow: true,
      toID: "",
      headimg: "",
      userheadimg: "",
      noedit: "",
      EditZD: false, //编辑诊单
      EditMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      EditXY: false, //编辑寻医
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      lookfeedback: false, //显示反馈表弹窗
      feedbackmsg: {}, //选择的反馈模板信息
      feedbackid: "", //反馈信息id
      feedbacknoedit: true, //反馈表是否可以编辑
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      bigpic:false,
      bigpicurl:"",
    };
  },
  components: {
    preview,
    xypreview,
    feedback,
  },
  watch: {
    lookhistory(o, n) {
      if (o) {
        this.show = true;
      }
    },
    historyrow(o, n) {
      if (o) {
        this.gethistory(o);
        this.toID = o.id;
        if (o.desc == "" || o.desc == null || o.desc == undefined) {
          this.talkname = o.name;
        } else {
          this.talkname = o.desc;
        }
      }
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:lookhistory", false);
      this.lookmoreshow = true;
    },
    gethistory(row) {
        /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
        if (
        row.headImg == "" ||
        row.headImg == null ||
        row.headImg == undefined ||
        row.headImg == "null" ||
        row.headImg == "undefined"
      ) {
        this.userheadimg = this.male;
      } else {
        this.userheadimg = row.headImg;
      }
      this.axios
        .get(this.serve + "/api/Message/get_user_history_message?id=" + row.id + "&index=1")
        .then((res) => {
          if (res.data.code == 1) {
            this.talklist = res.data.result;
            this.indexedDBindex = 2;
            this.$nextTick(() => {
              var div = document.getElementById("scrolldIV3");
              div.scrollTop = div.scrollHeight;
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    loadmore() {
      this.axios
        .get(
          this.serve +
            "/api/Message/get_user_history_message?id=" +
            this.toID +
            "&index=" +
            this.indexedDBindex
        )
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
                var newtalklist = res.data.result;
            var oldtalklist = this.talklist;
            this.talklist = [];
            this.talklist = newtalklist.concat(oldtalklist);
              if (this.indexedDBindex == 1) {
                this.talklist = [];
              }
              this.indexedDBindex++;
            } else {
              this.$message({
                message: "没有更多了",
                type: "warning",
              });
              this.lookmoreshow = false;
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    changetel(val) {
      var reg = /^(\d{3})\d*(\d{4})$/;
      var aaa = val.replace(reg, "$1****$2");
      return aaa;
    },
    big(url) {
      this.bigpic = true;
      this.bigpicurl = url;
    },
  },
};
</script>
<style>
.talkmain {
  width: 100%;
  overflow: auto;
  border: 1px solid #dcdfe6;
  float: none;
}
.timeline ,.lookhistory{
  text-align: center;
}
</style>
